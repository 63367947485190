import { useEffect } from 'react';
import { useUserStore } from './providers/UserProvider';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { checkUser } from './actions/user';

function App() {
  const {
    state: { isAuthenticated },
    dispatch
  } = useUserStore();

  useEffect(() => {
    dispatch(checkUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes =
    isAuthenticated !== null ? (
      <BrowserRouter>
        <Routes isAuthenticated={isAuthenticated} />
      </BrowserRouter>
    ) : null;

  return <div className="App">{routes}</div>;
}

export default App;
