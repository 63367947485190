const AppPrivateLayout = ({ children, onSignOut = () => {} }) => {
  return (
    <>
      <nav>
        <span onClick={onSignOut}>Sign out</span>
      </nav>
      <main>{children}</main>
    </>
  );
};

export default AppPrivateLayout;
