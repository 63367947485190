import { Redirect, Route } from 'react-router-dom';
import AppPrivateLayout from '../components/AppPrivateLayout';
import { logOut } from '../utils/firebase';

const PrivateRouter = ({ component: Component, isAuthenticated, ...rest }) => {
  const handleSignOut = () => {
    logOut();
  };

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <AppPrivateLayout onSignOut={handleSignOut}>
            <Component {...props} />
          </AppPrivateLayout>
        ) : (
          <Redirect to={'/login'} />
        )
      }
    />
  );
};

export default PrivateRouter;
