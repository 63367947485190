import { Redirect, Route } from 'react-router-dom';
import AppPublicLayout from '../components/AppPublicLayout';

const PublicRouter = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <AppPublicLayout>
            <Component {...props} />
          </AppPublicLayout>
        ) : (
          <Redirect to={'/admin'} />
        )
      }
    />
  );
};

export default PublicRouter;
