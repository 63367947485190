const AppPublicLayout = ({ children }) => {
  return (
    <>
      <nav>Iniciar</nav>
      <main>{children}</main>
    </>
  );
};

export default AppPublicLayout;
