import PrivateRouter from './PrivateRouter';
import { Switch } from 'react-router-dom';
import Dashboard from '../containers/Dashboard';
import Login from '../containers/Login';
import PublicRouter from './PublicRouter';

const Routes = ({ isAuthenticated }) => (
  <Switch>
    <PrivateRouter isAuthenticated={isAuthenticated} exact path={'/admin'} component={Dashboard} />
    <PublicRouter isAuthenticated={isAuthenticated} exact path={'/'} component={Login} />
    <PublicRouter isAuthenticated={isAuthenticated} exact path={'/login'} component={Login} />
  </Switch>
);

export default Routes;
