import { createContext, useContext, useReducer } from 'react';
import { middleware } from '../utils/middleware';
const initialState = { user: null, isAuthenticated: null };
export const UserContext = createContext();

const userReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'SET_USER': {
      return {
        ...state,
        user: { ...payload }
      };
    }
    case 'SET_IS_AUTHENTICATED': {
      return {
        ...state,
        isAuthenticated: payload
      };
    }
    case 'SIGN_OUT': {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export const useUserStore = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const value = { state, dispatch: middleware(dispatch, state) };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUser() {
  const { state } = useUserStore();

  return state.user;
}

export default UserProvider;
