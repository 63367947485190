import { auth } from '../utils/firebase';

export const setUser = user => ({
  type: 'SET_USER',
  payload: user
});

export const setIsAuthenticated = payload => ({
  type: 'SET_IS_AUTHENTICATED',
  payload
});

export const checkUser = () => (dispatch, state) => {
  auth.onAuthStateChanged(async user => {
    if (user) {
      const [currentUser] = user.providerData;
      const token = await auth.currentUser.getIdToken();

      dispatch(setUser({ ...currentUser, token }));
      dispatch(setIsAuthenticated(true));
    } else {
      dispatch(setIsAuthenticated(false));
    }
  });
};
